import React from 'react';
import css from './privacy_policy.module.scss';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes, legalDocuments} from "../components/shared/routes";
import SubpageLink2 from "../components/shared/InternalLinks/SubpageLink2";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import TextPage from "../components/shared/TextPage";
import MainButton, {mainButtonArrow} from "../components/shared/MainButton/MainButton";
import PdfWhiteIco from "../images/icons/document_pdf_white.svg";

const LegalDocumentsPage = () => {
    const routes = getRoutes();

    const pageTitle = routes.PrivacyPolicy.pageTitle;

    const content = (
        <div>
            <p align="center" style={{lineHeight: '115%', marginBottom: '0in'}}>
                <br/>
            </p>
            <p align="center" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>PRIVACY
              POLICY</b></span></font></font></font></p>
            <p align="center" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                lang="en-US"><b>2024.10.21</b></span></font></font></font></p>
            <p lang="en-US" align="center" style={{lineHeight: '115%', marginBottom: '0in'}}>
                <br/>
            </p>
            <p style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>I.
              INTRODUCTION</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">This
            document is a privacy policy (</span></font></font></font><font color="#000000"><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>policy</b></span></font></font></font><font
                color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">)
            of Deeplai P.S.A. with headquarters in Lublin (20-445 Poland), ul. Zemborzycka 72, exposed into Polish company register maintained by the
            District Court for Lublin-Wschód in Lublin with office in Świdnik
            (VI Commercial Department of the Court Register) under number
            0000918587, using the VAT-ID 9462691664. The share capital of the
            Company is 870&nbsp;800,00 PLN, hereinafter called a </span></font></font></font><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>Deeplai</b></span></font></font></font><font
                color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                lang="en-US">.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">The
            Deeplai’s policy in a general way explains the rules of processing
            and sharing personal data of Deeplai’s websites users which are
            operating at internet addresses:</span></font></font></font></p>
            <ul>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://deeplai.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://deeplai.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://deeplai.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://ai4creative.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://ai4creative.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><a href="https://productfingerprint.com/"><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Arial, sans-serif"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}><span
                    style={{background: '#f8f8f8'}}>productfingerprint.com</span></span></span></font></font></font></font></a>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><a href="https://productfingerprint.pl/"><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Arial, sans-serif"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}><span
                    style={{background: '#f8f8f8'}}>productfingerprint.pl</span></span></span></font></font></font></font></a>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#70ad47"><font face="Roboto"><font
                    size={2} style={{fontSize: '10pt'}}><span lang="en-US">https://</span></font></font></font><font
                    color="#0563c1"><a href="http://productfingerprint.eu/" target="_blank"><font color="#70ad47"><font
                    face="Arial, sans-serif"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}><span style={{background: '#f8f8f8'}}>productfingerprint.eu</span></span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                       style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://timberidentity.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                      style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberidentity.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                      style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberidentity.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://woodidentity.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://woodidentity.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://woodidentity.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://woodidentity.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberid.tech/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                  style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberid.tech</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#0563c1"><a
                    href="https://timberid.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://timberid.eu</span></span></font></font></font></a></font>
                </p></li>
            </ul>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">By
            accessing any of the aforementioned websites or by sending to Deeplai
            any personal data through them, data will be processed in accordance
            with the policy. These websites are used to promote and sell the
            Deeplai solutions only to entrepreneurs.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">The
            policy also contains information on how we use the so-called cookies
            and other technologies that allow to storage information about
            websites on end-user’s devices. The privacy policy is constantly
            updating - in line with the changes resulting from the technology
            used on the Deeplai’s websites.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>II.
              PERSONAL DATA CONTROLLER</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Controller
            is Deeplai.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>WHO
              CAN I CONTACT IN MATTERS REGARDING THE PROTECTION OF PERSONAL DATA?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">You
            can contact Deeplai support in all matters regarding personal data
            protection. You can contact us personally at the correspondence
            address (ul. Zemborzycka 72, 20-445 Lublin, Poland), by phone: +48 81
            743 51 09 or by e-mail: </span></font></font></font><font color="#70ae47"><font face="Roboto"><font size={2}
                                                                                                                style={{fontSize: '10pt'}}><span
                lang="en-US">contact@deeplai.com.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>WHAT
              IS THE PURPOSE OF PROCESSING MY PERSONAL DATA?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Deeplai
            process your personal data for the following purposes, alongside
            which are given the legal grounds for processing:</span></font></font></font></p>
            <ol>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">in
                purpose to answer a question or other message sent by the user -
                according to the GDPR, we assume that if you decide to send a
                message by the contact form or e-mail and if you are providing your
                personal data, the controller is authorized to process your data -
                legal basis is art. 6.1.a of GDPR;</span></font></font></font></p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">fulfilling
                the controller’s legitimate interest which rely on conducting
                analytical and statistical activities that regard users' activity,
                as well as their preferences in purpose to improve the
                functionalities and services provided - legal basis is art. 6.1.f of
                GDPR.</span></font></font></font></p></li>
            </ol>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>WHO
              WILL BE THE RECIPIENT OF MY PERSONAL DATA?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Your
            personal data will be disclosed by the controller to cooperating
            entities (recipients). The disclosure of your data is always based on
            a data processing agreement, which is a fully compliant practice with
            the GDPR. The entities to which Deeplai discloses your personal data
            will be:</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">1.
            OVH Polska sp. z o. o. - IT infrastructure and services provider that
            maintains individual websites and services used by Deeplai.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.ovh.com/world/support/terms-and-conditions/"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://www.ovh.com/world/support/terms-and-conditions/</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.ovh.com/ca/en/support/privacy-policy.xml"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://www.ovh.com/ca/en/support/privacy-policy.xml</span></span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">2.
            Google LLC (California, United States of America) - IT infrastructure
            and services provider used by Deeplai, including Google Analytics
            service provider that allows us to conduct analytical and statistical
            activities.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://policies.google.com/terms"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://policies.google.com/terms</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://policies.google.com/privacy"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://policies.google.com/privacy</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://cloud.google.com/terms"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://cloud.google.com/terms</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://cloud.google.com/privacy"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://cloud.google.com/privacy</span></span></font></font></font></a></font><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">
          </span></font></font></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <a href="https://business.safety.google/privacy"><font color="#0563c1"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>business.safety.google/privacy</span></span></font></font></font></font></a><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">
          </span></font></font></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">3.
            Amazon Web Services Inc. (California, United States of America) - IT
            infrastructure and services provider used by Deeplai.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://aws.amazon.com/service-terms/"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://aws.amazon.com/service-terms/</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://aws.amazon.com/privacy/"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://aws.amazon.com/privacy/</span></span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">4.
            Apple Inc. (California, United States of America)</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://www.apple.com/legal/internet-services/itunes/dev/stdeula/</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '200%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.apple.com/legal/privacy/en-ww/"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://www.apple.com/legal/privacy/en-ww/</span></span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>ARE
              MY PERSONAL DATA BEING OUTSIDE THE EUROPEAN UNION?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Deeplai
            transfers your personal data to a third country within the meaning of
            the GDPR. The third country is the United States of America. The US
            is a country that does not belong to the European Economic Area and
            does not meet such high requirements for the protection of personal
            data as countries belonging to the European Economic Area. The
            personal data transferred to US are secure. The controller entrusts
            their processing on the basis of a data processing agreement. The
            processor has been certified in the Privacy Shield Framework,
            approved by the decision of the European Commission.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>HOW
              LONG WILL MY DATA BE PROCESSED?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">The
            controller processes your data for various periods of time. It
            depends on the purpose of data processing and their type. We can
            indicate the following periods:</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">1.
            for 3 years from the day that you contacted Deeplai;</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">2.
            for 3 years from the day that you visited the website managed by
            Deeplai.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>WHAT
              RIGHTS DO I HAVE IN CONNECTION WITH THE PROCESSING OF MY PERSONAL
              DATA?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">You
            have the right to request for the controller access to and
            rectification, erasure, restriction of&nbsp;processing or to object
            to processing your personal data. You only have these rights in
            situations where such a request is actually justified.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>DO
              I HAVE TO PROVIDE MY PERSONAL DATA?</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">You
            do not have to provide your personal data. If you do not want that
            your personal data will be subjected to analytical and statistical
            activities, you can use special browser plugins that prevent data
            collection (e.g. by providing fictitious information about your
            browser or your behavior). Also filling out the contact form or
            preparing and sending an e-mail to the controller is completely
            voluntary but necessary to contact the controller.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>THE
              RIGHT TO COMPLAIN</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">You
            have the right to lodge a complaint about processing your personal
            data by the controller. Complaint should be submitted to the
            supervisory authority In Poland - which is the President of the
            Personal Data Protection Office with headquarters in ul. Stawki 2,
            00-193 Warsaw, Poland.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><b>III.
              STORING INFORMATION IN THE USER'S FINAL TELECOMMUNICATION DEVICE</b></span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Deeplai’s
            websites do not automatically collect any information, except for
            information contained in so-called cookies. Deeplai uses the
            so-called permanent and session cookies. Session cookies are stored
            on the endusers’ device until they leave the website or turn off
            the browser. Permanent cookies are stored on the endusers’ device
            for the time specified in their parameters. It can be the time of the
            browsing session, the time specified in advance or the time until
            their removal by the user.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Websites
            use the following types of cookies:</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">1.
            files that are necessary for the operation of website - they enable
            the website to be used correctly, adapt its content to the device and
            take care of basic issues related to the security of your data. You
            cannot disable these files, but you can block them. If blocked, some
            page elements will not work. These cookies do not store any personal
            data.</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">2.
            files that allow for analytical and statistical activities - they
            enable to collect of information about the user's activity on
            websites, which helps Deeplai understand how you use the website,
            which content is interesting and which you skip. The information
            collected in this way allows us to improve the operation of our
            websites. These data are aggregated and therefore remain anonymous.
            You may not agree to the use of these cookies - then we will not know
            when as a user you visited our website and what activities you
            performed on it. Opting out of these cookies may result in some
            features of the site being unavailable.</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">3.
            files that allow you to personalize the functionality of the website
            - they allow you to remember the settings you choose or your
            preferences. Using these files allows you to customize the website to
            your personal preferences based on your previous visits and settings.
            You can choose not to use these cookies. In this case, we will not
            have information about your preferences, and some functions of the
            website may not work properly.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">All
            cookies used by Deeplai contain the name of the website, identify
            your devices and browser. They also let you know if your device has
            ever visited the Deeplai website before. Sometimes, in combination
            with other data, the information contained in cookies may constitute
            personal data. The information collected includes, among others:</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">1.
            data about your device - it’s type, model, unique identifiers such
            as MAC address or IP address, information about the operating system,
            it’s version and setup, language of operation, screen resolution,
            version and resolution of the web browser;</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">2.
            data about how you use the Deeplai website - the date of your last
            visit to the Deeplai website, it’s length, type of pages and
            subpages visited, their URLs, version of the web browser;</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">3.
            data on your location - IP address, geographical location data
            obtained through various positioning technologies, other geolocation
            data you provided, including the names of Wi-Fi points or other
            sensors providing information about devices nearby.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">Some
            of the cookies used and functionalities are the solutions provided as
            part of analytical services by an external provider. They allow you
            to collect anonymous information about user visits to the websites,
            including detailed information on the displayed functions, the time
            spent in each functionality and how to navigate between individual
            pages. Data processed using this tool may be transferred outside the
            European Economic Area.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">The
            user has the possibility to disable cookies. It is possible by
            changing the browser settings accordingly. Disabling cookies may
            affect the functioning of the website. If you not change your browser
            settings, that mean you accept the use of cookies.</span></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">In
            accordance with the provisions of Polish law, we assume that each
            user may express or refuse consent to the use of cookies through the
            appropriate configuration of his browser. Such consent may be
            withdrawn at any time. The user can also delete cookies stored on his
            device at any time. Withdrawal of consent does not affect the
            lawfulness of processing data contained in cookies before its
            withdrawn. Withdrawal of consent expressed via the browser option is
            described in detail on the websites of the browsers' providers -
            below you will find hyperlinks to the most popular ones:</span></font></font></font></p>
            <ol>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Internet
                Explorer:
              </span></font></font></font><font color="#0563c1"><a
                    href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-deletemanage-cookies"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-deletemanage-cookies</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Mozilla
                Firefox: </span></font></font></font><font color="#0563c1"><a
                    href="http://support.mozilla.org/pl/kb/ciasteczka"><font color="#70ad47"><font face="Roboto"><font
                    size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>http://support.mozilla.org/pl/kb/ciasteczka</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Google
                Chrome: </span></font></font></font><font color="#0563c1"><a
                    href="https://support.google.com/chrome/answer/95647?hl=pl"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.google.com/chrome/answer/95647?hl=pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Opera</span></font></font></font><font face="Roboto"><font size={2}
                                                                                                style={{fontSize: '10pt'}}><span
                    lang="en-US">:
            </span></font></font><font color="#0563c1"><a
                    href="http://help.opera.com/Windows/12.10/pl/cookies.html"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>http://help.opera.com/Windows/12.10/pl/cookies.html</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Safari:
              </span></font></font></font><font color="#0563c1"><a
                    href="https://support.apple.com/kb/PH5042?locale=en-GB"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.apple.com/kb/PH5042?locale=en-GB</span></span></font></font></font></a></font>
                </p></li>
            </ol>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">You
            can also verify your settings in your browser at any time, also in
            relation to individual cookies. For this purpose, you can use, among
            others with the following tools:</span></font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">1.
          </span></font></font></font><font color="#0563c1"><a
                href="http://www.youronlinechoices.com/pl/twojewybory"><font color="#70ad47"><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                style={{textDecoration: 'none'}}>http://www.youronlinechoices.com/pl/twojewybory</span></span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">2.
          </span></font></font></font><font color="#0563c1"><a href="http://optout.aboutads.info/?c=2&lang=EN"><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                style={{textDecoration: 'none'}}>http://optout.aboutads.info/?c=2&amp;lang=EN</span></span></font></font></font></a></font>
            </p>
            <p lang="en-US" align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                <br/>
                <br/>
            </p>
            <p lang="en-US" align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                <br/>
                <br/>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>POLITYKA
                PRYWATNOŚCI</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>I.
                WPROWADZENIE</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Niniejszy
                dokument jest polityką prywatności (dalej: </font></font></font><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>polityka)
            </b></font></font></font><font color="#000000"><font face="Roboto"><font size={2}
                                                                                     style={{fontSize: '10pt'}}>Deeplai
                P.S.A. z siedzibą przy ul.&nbsp;Zemborzycka 72, (20-445), wpisana do
                Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego
                przez Sąd Rejonowy Lublin-Wschód w Lublinie z siedzibą w Świdniku
                (VI Wydział Gospodarczy Krajowego Rejestru Sądowego) pod numerem
                0000918587, posługującą się NIP&nbsp;9462691664, z kapitałem
                zakładowym wynoszącym 870&nbsp;800,00zł, dalej nazywaną po prostu
            </font></font></font><font color="#000000"><font face="Roboto"><font size={2}
                                                                                 style={{fontSize: '10pt'}}><b>Deeplai</b></font></font></font><font
                color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Polityka
                Deeplai wyjaśnia w ogólny sposób zasady przetwarzania i
                udostępniania danych osobowych użytkowników stron internetowych
                należących do Deeplai i działających pod adresami:</font></font></font></p>
            <ul>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://deeplai.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://deeplai.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://deeplai.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://deeplai.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://ai4creative.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://ai4creative.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><a href="https://productfingerprint.com/"><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Arial, sans-serif"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}><span
                    style={{background: '#f8f8f8'}}>productfingerprint.com</span></span></span></font></font></font></font></a>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><a href="https://productfingerprint.pl/"><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Arial, sans-serif"><font size={2}
                                                                                               style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}><span
                    style={{background: '#f8f8f8'}}>productfingerprint.pl</span></span></span></font></font></font></font></a>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#70ad47"><font face="Roboto"><font
                    size={2} style={{fontSize: '10pt'}}><span lang="en-US">https://</span></font></font></font><font
                    color="#0563c1"><a href="http://productfingerprint.eu/" target="_blank"><font color="#70ad47"><font
                    face="Arial, sans-serif"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}><span style={{background: '#f8f8f8'}}>productfingerprint.eu</span></span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.com/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                       style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://timberidentity.com</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                      style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberidentity.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberidentity.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                      style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberidentity.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://woodidentity.pl/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://woodidentity.pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://woodidentity.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                    style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://woodidentity.eu</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0in'}}><font color="#0563c1"><a
                    href="https://timberid.tech/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                  style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://timberid.tech</span></span></font></font></font></a></font>
                </p></li>
                <li><p style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#0563c1"><a
                    href="https://timberid.eu/"><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                style={{fontSize: '10pt'}}><span
                    lang="en-US"><span style={{textDecoration: 'none'}}>https://timberid.eu</span></span></font></font></font></a></font>
                </p></li>
            </ul>
            <p align="justify" style={{lineHeight: '115%', marginTop: '0.08in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Wchodząc
                    na którąkolwiek z wyżej wymienionych stron lub przesyłając
                    Deeplai jakiekolwiek dane osobowe za ich pośrednictwem, będą one
                    przetwarzane w sposób opisany w polityce. Strony te są
                    wykorzystywane do promowania i sprzedaży rozwiązań Deeplai
                    wyłącznie przedsiębiorcom.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Polityka
                zawiera również informacje na temat sposobu wykorzystywania tzw.
                ciasteczek i innych technologii pozwalających na przechowywanie
                informacji w urządzeniach użytkownika stron internetowych. Polityka
                prywatności jest aktualizowana w sposób bieżący - zgodnie ze
                zmianami wynikającymi z wykorzystywanej na stronach internetowych
                Deeplai technologii.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>II.
                DANE OSOBOWE</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>ADMINISTRATOR
                DANYCH OSOBOWYCH</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Administratorem
                danych osobowych jest Deeplai.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>Z
                KIM MOGĘ KONTAKTOWAĆ SIĘ W SPRAWACH DOTYCZĄCYCH OCHRONY DANYCH?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>We
                wszystkich sprawach dotyczących ochrony danych osobowych możesz
                kontaktować się z działem pomocy Deeplai. Kontakt możesz
                realizować w sposób osobisty, pod adresem korespondencyjnym
                (ul.&nbsp;Zemborzycka 72, 20-445 Lublin), pod numerem telefonu </font></font></font><font color="#320000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>+48
                81 743 51 09 </font></font></font><font color="#000000"><font face="Roboto"><font size={2}
                                                                                                  style={{fontSize: '10pt'}}>lub
                wysyłając wiadomość na adres e-mail </font></font></font><font color="#70ae47"><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}>contact@deeplai.com</font></font></font><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}>.</font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>W
                JAKIM CELU SĄ PRZETWARZANE MOJE DANE OSOBOWE?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Deeplai
                przetwarzana Twoje dane osobowe w następujących celach, obok
                których podano podstawy prawne przetwarzania:</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>1.
                w celu udzielenia odpowiedzi na pytanie lub inną wiadomość
                przesłaną przez użytkownika - zgodnie z RODO zakładamy, że skoro
                decydujesz się na przesłanie wiadomości za pomocą formularza
                kontaktowego lub poczty e-mail, to podając dane osobowe
                administrator jest uprawniony do przetwarzania Twoich danych na
                podstawie art. 6 ust. 1 lit. a RODO;</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>2.
                realizacja prawnie uzasadnionego interesu administratora polegającego
                na prowadzeniu działań analitycznych i statystycznych dotyczących
                aktywności użytkowników, a także ich preferencji w celu poprawy
                stosowanych funkcjonalności i świadczonych usług na podstawie art.
                6 ust. 1 lit. f RODO.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>KTO
                BĘDZIE ODBIORCĄ MOICH DANYCH OSOBOWYCH?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Twoje
                dane osobowe będą ujawniane przez administratora podmiotom
                współpracującym (odbiorcom). Ujawnianie Twoich danych odbywa się
                zawsze na podstawie umowy powierzenia przetwarzania danych osobowych,
                co jest praktyką w pełni zgodną z RODO. Podmiotami, którym
                Deeplai ujawni Twoje dane osobowe będą:</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>1.
                    OVH Polska sp. z o.o. - dostawca infrastruktury IT utrzymującej
                    poszczególne strony internetowe oraz usług wykorzystywanych przez
                    Deeplai.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.ovh.com/world/support/terms-and-conditions/"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://www.ovh.com/world/support/terms-and-conditions/</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.ovh.com/ca/en/support/privacy-policy.xml"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://www.ovh.com/ca/en/support/privacy-policy.xml</span></font></font></font></a></font>
            </p>
            <ol start={2}>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Google
                        LLC (Kalifornia, Stany Zjednoczone Ameryki) - dostawca
                        infrastruktury oraz usług IT wykorzystywanych przez Deeplai, w tym
                        usługi Google Analytics pozwalającej na prowadzenie działań
                        analitycznych i statystycznych.</font></font></font></p></li>
            </ol>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://policies.google.com/terms"><font color="#70ae47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://policies.google.com/terms</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://policies.google.com/privacy"><font color="#70ae47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://policies.google.com/privacy</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://cloud.google.com/terms"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://cloud.google.com/terms</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://cloud.google.com/privacy"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://cloud.google.com/privacy</span></font></font></font></a></font><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>
            </font></font></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <a href="https://business.safety.google/privacy"><font color="#0563c1"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://</span></span></font></font></font></font><font
                    color="#0563c1"><font color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>business.safety.google/privacy</span></span></font></font></font></font></a><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">
          </span></font></font></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US">3.
            Amazon Web Services Inc. </span></font></font></font><font color="#000000"><font face="Roboto"><font
                size={2} style={{fontSize: '10pt'}}>(Kalifornia,
                Stany Zjednoczone Ameryki) - dostawca infrastruktury IT oraz usług
                wykorzystywanych przez Deeplai.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://aws.amazon.com/service-terms/"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://aws.amazon.com/service-terms/</span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#70ad47"> </font><font color="#0563c1"><a href="https://aws.amazon.com/privacy/"><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                style={{textDecoration: 'none'}}>https://aws.amazon.com/privacy/</span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>4.
                    Apple Inc. (Kalifornia, Stany Zjednoczone Ameryki)</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://www.apple.com/legal/internet-services/itunes/dev/stdeula/</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '200%', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#0563c1"><a href="https://www.apple.com/legal/privacy/en-ww/"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    style={{textDecoration: 'none'}}>https://www.apple.com/legal/privacy/en-ww/</span></font></font></font></a></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>CZY
                MOJE DANE OSOBOWE TRAFIAJĄ POZA UNIĘ EUROPEJSKĄ?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Deeplai
                przekazuje Twoje dane osobowe do państwa trzeciego w rozumieniu RODO
                czyli do Stanów Zjednoczonych Ameryki. USA to państwo, które nie
                należy do Europejskiego Obszaru Gospodarczego i nie spełnia tak
                wysokich wymogów ochrony danych osobowych jak państwa należące do
                Europejskiego Obszaru Gospodarczego. Przekazywane dane osobowe są
                jednak bezpieczne - administrator powierza ich przetwarzanie na
                podstawie umowy powierzenia przetwarzania, podmiot przetwarzający
                został certyfikowany w programie Tarcza Prywatności (ang. </font></font></font><font
                color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><i>Privacy
            </i></font></font></font><font color="#000000"><font face="Roboto"><font size={2}
                                                                                     style={{fontSize: '10pt'}}>Shield),
                zatwierdzonym decyzją Komisji Europejskiej.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>JAK
                DŁUGO BĘDĄ PRZETWARZANE MOJE DANE?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Administrator
                przetwarza Twoje dane przez różne czasu. Zależy to od celu
                przetwarzania danych oraz ich rodzaju. Możemy wskazać następujące
                okresy:</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>1.
                    przez 3 lata licząc od dnia, w którym skontaktowałeś się z
                    Deeplai;</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>2.
                    przez 3 lata licząc od dnia, w którym odwiedziłeś stronę
                    zarządzaną przez Deeplai.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>JAKIE
                PRAWA PRZYSŁUGUJĄ MI W ZWIĄZKU Z PRZETWARZANIEM MOICH DANYCH
                OSOBOWYCH?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Przysługuje
                Tobie prawo do żądania od administratora dostępu do danych, ich
                sprostowania, usunięcia, ograniczenia lub wniesienia sprzeciwu
                dotyczącego przetwarzania Twoich danych osobowych. Prawa te
                przysługują Tobie tylko w sytuacjach, w których wniesienie takiego
                żądania jest faktycznie uzasadnione.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>CZY
                MUSZĘ PODAWAĆ MOJE DANE OSOBOWE?</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Nie
                musisz podawać swoich danych osobowych. Jeśli nie chcesz aby Twoje
                dane osobowe były poddawane działaniom analitycznym i statystycznym
                możesz skorzystać ze specjalnych wtyczek do przeglądarki, które
                uniemożliwiają zbieranie danych (np. poprzez podawanie fikcyjnych
                informacji o Twojej przeglądarce czy Twoim sposobie zachowania).
                Również wypełnienie formularza kontaktowego czy sporządzenie i
                wysłanie wiadomości e-mail do administratora jest w pełni
                dobrowolne lecz niezbędne do tego, aby skontaktować się z
                administratorem.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>PRAWO
                DO SKARGI</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Przysługuje
                Tobie prawo do wniesienia skargi w związku z tym jak przetwarzane są
                Twoje dane osobowe przez administratora. Skargę możesz wnieść do
                organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych
                Osobowych z siedzibą w Warszawie (00-193), przy ul. Stawki 2.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}><b>III.
                PRZECHOWYWANIE INFORMACJI W TELEKOMUNIKACYJNYM URZĄDZENIU KOŃCOWYM
                UŻYTKOWNIKA</b></font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Strony
                internetowe Deeplai nie zbierają w sposób automatyczny żadnych
                informacji, z wyjątkiem informacji zawartych w tzw. ciasteczkach
                (ang. </font></font></font><font color="#000000"><font face="Roboto"><font size={2}
                                                                                           style={{fontSize: '10pt'}}><i>cookies</i></font></font></font><font
                color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>).
                Deeplai wykorzystuje względem użytkowników stron internetowych
                tzw. stałe i sesyjne pliki cookies. Sesyjne pliki cookies są
                przechowywane w urządzeniu użytkownika do czasu opuszczenia strony
                internetowej lub wyłączenia przeglądarki. Stałe pliki cookies są
                przechowywane w urządzeniu końcowym użytkownika przez określony w
                ich parametrach czas - może to być czas sesji, czas określony z
                góry lub czas do ich usunięcia przez użytkownika. </font></font></font>
            </p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Strony
                internetowe wykorzystują następujące typy plików cookie:</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>1.
                    pliki niezbędne do funkcjonowania strony internetowej - umożliwiają
                    korzystanie z zawartości strony w sposób prawidłowy, pozwalają
                    dopasować jej zwartość do urządzenia i dbają o podstawowe
                    kwestie związane z bezpieczeństwem Twoich danych - nie możesz
                    wyłączyć tych plików, ale możesz je zablokować. W przypadku
                    blokady niektóre elementy strony nie będą działać. Te pliki
                    cookies nie przechowują żadnych danych osobowych.</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>2.
                    pliki pozwalające na działania analityczne i statystyczne -
                    umożliwiają zbieranie informacji o sposobie korzystania przez
                    użytkownika ze stron internetowych, co pomaga Deeplai zrozumieć w
                    jaki sposób korzystasz ze strony internetowej, które treści są
                    interesujące, a które pomijasz. Informacje w ten sposób zebrane
                    pozwalają doskonalić działanie naszych stron internetowych. Dane
                    te są agregowane, w związku z czym pozostają anonimowe. Możesz
                    nie wyrazić zgody na stosowanie tych plików cookie - wówczas nie
                    będziemy wiedzieć kiedy jako użytkownik odwiedziłeś naszą
                    stronę internetową oraz jakie działania na niej wykonywałeś.
                    Rezygnacja z tych plików cookie może powodować, że niektóre
                    funkcje strony będą niedostępne.</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>3.
                    pliki pozwalające na personalizację funkcjonalności strony
                    internetowej - umożliwiają zapamiętanie wybranych przez Ciebie
                    ustawień czy Twoich preferencji. Korzystanie z tych plików
                    umożliwia dostosowanie strony internetowej do Twoich osobistych
                    preferencji w oparciu o Twoje wcześniejsze wizyty i ustawienia.
                    Możesz nie wyrazić zgody na stosowanie tych plików cookie. Wówczas
                    nie będziemy posiadać informacji o Twoich preferencjach, a niektóre
                    funkcje strony internetowej mogą nie działać prawidłowo.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Wszystkie
                pliki cookies wykorzystywane przez Deeplai zawierają nazwę strony
                internetowej, identyfikują Twoje urządzenia i przeglądarkę.
                Pozwalają dowiedzieć się także, czy Twoje urządzenie odwiedzało
                już kiedyś stronę internetową Deeplai. Niekiedy, w połączeniu z
                innymi danymi, informacje zawarte w plikach cookies mogą stanowić
                dane osobowe. Wśród zbieranych informacji znajdują się m.in.:</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>1.
                    dane dotyczące Twojego urządzenia - jego typ, model, unikalne
                    identyfikatory takie jak adres MAC czy adres IP, informacje o
                    systemie operacyjnym, jego wersji i ustawieniach, języku działania,
                    rozdzielczości ekranu, wersji i rozdzielczości przeglądarki
                    internetowej;</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>2.
                    dane dotyczące sposobu korzystania przez Ciebie ze strony
                    internetowej Deeplai - data ostatniej wizyty na stronie Deeplai, jej
                    długości, rodzaju odwiedzanych stron i podstron, ich adresów URL,
                    wersji przeglądarki internetowej;</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>3.
                    dane dotyczące Twojej lokalizacji - adres IP, dane o lokalizacji
                    geograficznej uzyskane za pomocą różnych technologii
                    pozycjonowania, inne podane przez Ciebie dane geolokalizacyjne, w tym
                    nazwy punktów Wi-Fi lub innych czujników dostarczających
                    informacje o urządzeniach w pobliżu.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Część
                z wykorzystywanych plików cookie oraz funkcjonalności to
                rozwiązania dostarczane w ramach usług analitycznych przez
                zewnętrznego dostawcę. Pozwalają one zbierać anonimowe informacje
                na temat odwiedzin użytkowników stron internetowych, w tym
                szczegółowe informacje na temat wyświetlanych funkcji, czasu
                spędzanego w poszczególnych funkcjonalnościach oraz sposobu
                przechodzenia pomiędzy poszczególnymi stronami. Dane przetwarzane
                przy wykorzystaniu tego narzędzia mogą być przekazywane poza
                Europejski Obszar Gospodarczy.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Użytkownik
                ma możliwość zablokowania zapisywania plików cookies poprzez
                odpowiednią zmianę ustawień przeglądarki internetowej. Wyłączenie
                cookies może mieć wpływ na funkcjonowanie strony. Brak zmiany
                ustawień przeglądarki oznacza akceptację dla stosowanych cookies.</font></font></font></p>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Zgodnie
                z przepisami polskiego prawa przyjmujemy, iż każdy użytkownik może
                wyrazić lub odmówić zgody na wykorzystywanie plików cookies
                poprzez odpowiednią konfigurację swojej przeglądarki. Zgoda tak
                wyrażona może być w każdym momencie wycofana. Użytkownik może
                również w dowolnym momencie usunąć pliki cookie przechowywane na
                swoim urządzeniu. Wycofanie zgody nie wpływa na zgodność z prawem
                przetwarzania danych zawartych w plikach cookie do chwili jej
                wycofania. Wycofanie zgody wyrażonej za pośrednictwem opcji
                przeglądarki jest szczegółowo opisane na stronach internetowych
                dostawców poszczególnych przeglądarek - poniżej znajdziesz
                hiperłącza do tych najpopularniejszych:</font></font></font></p>
            <ol>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Internet
                Explorer:
              </span></font></font></font><font color="#0563c1"><a
                    href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-deletemanage-cookies"><font
                    color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                    lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-deletemanage-cookies</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Mozilla
                Firefox: </span></font></font></font><font color="#0563c1"><a
                    href="http://support.mozilla.org/pl/kb/ciasteczka"><font color="#70ad47"><font face="Roboto"><font
                    size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>http://support.mozilla.org/pl/kb/ciasteczka</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Google
                Chrome:</span></font></font></font><font color="#70ad47"><font face="Roboto"><font size={2}
                                                                                                   style={{fontSize: '10pt'}}><span
                    lang="en-US">
              </span></font></font></font><font color="#0563c1"><a
                    href="https://support.google.com/chrome/answer/95647?hl=pl"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.google.com/chrome/answer/95647?hl=pl</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Opera:
              </span></font></font></font><font color="#0563c1"><a
                    href="http://help.opera.com/Windows/12.10/pl/cookies.html"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>http://help.opera.com/Windows/12.10/pl/cookies.html</span></span></font></font></font></a></font>
                </p></li>
                <li><p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}>
                    <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                        lang="en-US">Safari:
              </span></font></font></font><font color="#0563c1"><a
                    href="https://support.apple.com/kb/PH5042?locale=en-GB"><font color="#70ad47"><font
                    face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span lang="en-US"><span
                    style={{textDecoration: 'none'}}>https://support.apple.com/kb/PH5042?locale=en-GB</span></span></font></font></font></a></font>
                </p></li>
            </ol>
            <p align="justify" style={{lineHeight: '115%', marginBottom: '0.08in'}}><font color="#000000"><font
                face="Roboto"><font size={2} style={{fontSize: '10pt'}}>Masz
                też możliwość zweryfikowania w każdej chwili swoich ustawień w
                przeglądarce, również w stosunku do poszczególnych plików
                cookie. W tym celu możesz skorzystać m.in. z następujących
                narzędzi:</font></font></font></p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0in'}}>
                <font color="#000000"><font face="Roboto"><font size={2}
                                                                style={{fontSize: '10pt'}}>1.</font></font></font><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>
            </font></font></font><font color="#0563c1"><a href="http://www.youronlinechoices.com/pl/twojewybory"><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                style={{textDecoration: 'none'}}>http://www.youronlinechoices.com/pl/twojewybory</span></font></font></font></a></font>
            </p>
            <p align="justify"
               style={{lineHeight: '115%', textIndent: '-0.2in', marginLeft: '0.3in', marginBottom: '0.08in'}}>
                <font color="#000000"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}>2.
                </font></font></font><font color="#0563c1"><a href="http://optout.aboutads.info/?c=2&lang=EN"><font
                color="#70ad47"><font face="Roboto"><font size={2} style={{fontSize: '10pt'}}><span
                style={{textDecoration: 'none'}}>http://optout.aboutads.info/?c=2&amp;lang=EN</span></font></font></font></a></font>
            </p>
        </div>

    );

    const pageHeader = <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        <div style={{marginRight: 40}}>{pageTitle}</div>
        <div>
            <a href={legalDocuments.deeplai.privacyPolicy}>
                <MainButton label={"Download"} arrow={mainButtonArrow.rightDown} leftIcon={PdfWhiteIco}/>
            </a>
        </div>
    </div>

    const manageCookies = (
        <div className={css.manageCookiesWrapper}>
            Manage <SubpageLink2 link={routes.CookieSettings.route}>{routes.CookieSettings.pageTitle}</SubpageLink2>
        </div>
    )

    return (
        <Layout>
            <SEO title={pageTitle} description={'Privacy policy'}/>

            <PageTitle>{pageHeader}</PageTitle>

            <TextPage
                maxWidth={610}
                content={content}
            />

            {manageCookies}

            <Footer/>
        </Layout>
    );
};

export default LegalDocumentsPage;

