import React from 'react';
import css from "./textPage.module.scss";
import useBreakpoint from "../hooks/useBreakpoint";

const TextPage = props => {
  /*
  How to update content for pages copied from docx (like Privacy Policy):
  1. Open doc file in LibreOffice.
  2. Go to File > Preview in Web Browser.
  3. Go to browser, open dev tools, copy <body>.
  4. Go to https://magic.reactjs.net/htmltojsx.htm, uncheck "Create class" and paste <body>.
  5. Copy here as source.
  6. (Remove div.header (or <div title="header">)containing logo and website url)
  7. Replace each mailto link - i.e:
      replace
      <a href="mailto:contact_deeplai_com"><font ...>contact_deeplai_com<... /font></a>
      with
      <font ...><CompanyContactMail /><... /font>
 */

  const { content, maxWidth = '' } = props;

  const breakpoint = useBreakpoint()

  return (
    <div className={css.wrapper} data-viewport={breakpoint.viewport}>
      <div className={css.wrapperInner} style={{ maxWidth: maxWidth}}>
        <div className={css.content}>
          {content}
        </div>
      </div>
    </div>
  )
};

export default TextPage;

